import React, { useCallback } from "react";
import { Helmet } from "react-helmet";

import { Company } from "../types";

interface Props {
  company: Company;
  children: any;
}

export default function Layout({ children, company }: Props) {
  const { name } = company;

  return (
    <React.StrictMode>
      <Helmet>
        {renderProductionScripts()}
        <title>{name.value}</title>
        <meta charSet="utf-8" />

        <meta name="description" content={company.description.value} />

        <meta name="charset" content="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale = 1, maximum-scale = 1, user-scalable = no"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href={"/site.webmanifest?ver=" + Date.now()} />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#f58220" />
        <link rel="shortcut icon" href="/favicon.ico" />

        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      {children}
    </React.StrictMode>
  );
}

function renderProductionScripts() {
  if (process.env.NODE_ENV !== "production") return [];

  const gtagTrackingId = "UA-91874705-1";

  return [
    <script
      key="usercentrics"
      id="C3tLDbuhf"
      type="application/javascript"
      src="https://app.usercentrics.eu/latest/main.js"
    />,
    <script
      key="gtag-external"
      type="text/plain"
      data-usercentrics="Google Analytics"
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${gtagTrackingId}`}
    />,
    <script
      key="gtag-internal"
      type="text/plain"
      data-usercentrics="Google Analytics"
    >
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', ${gtagTrackingId}', { 'anonymize_ip': true, 'send_page_view': false });
      `}
    </script>,
  ];
}
